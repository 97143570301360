<template>
  <div class="sku-container">
    <div class="sku-title">商品SKU管理</div>
    <div class="queryItems mb20">
      <div class="u-start-flex mr15">
        <div class="default-font">SKU编码：</div>
        <el-input v-model="query.SKU" size="small" class="mr15" clearable style="width: 20rem" placeholder="SKU编码"></el-input>
      </div>
      <div class="u-start-flex mr15">
        <div class="default-font">商品品类：</div>
        <el-select size="small" v-model="query.GoodsTypeId" clearable style="width: 20rem">
          <el-option
              v-for="item in options['product']"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15">
        <div class="default-font">库存状态：</div>
        <el-select size="small" v-model="query.InventoryTypeId" clearable style="width: 20rem">
          <el-option
              v-for="item in options['inventory']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" class="" size="small" icon="el-icon-search" @click="getData">查询</el-button>
      <el-button type="primary" class="" size="small" icon="el-icon-plus" @click="addRow">添加</el-button>
      <el-button type="primary" class="" size="small" icon="el-icon-upload2" @click="addRow">批量导入</el-button>
    </div>
    <el-table
        :data="listData"
        border
        ref="multipleTable"
        size="mini"
        :height="tableHeight"
        :max-height="tableHeight"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="获取数据中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="商品品类" prop="ProductName" align="center"></el-table-column>
      <el-table-column label="SKU编码" prop="SKUCode" align="center" width="180">
        <template slot-scope="scope">
          <el-popover
              placement="right"
              trigger="click">
            <div>
              <el-button slot="reference" type="text" size="mini" @click="printSkuCode(scope.row.SKUCode)">打印SKU</el-button>
              <el-button slot="reference" type="text" size="mini" @click="showInventory(scope.row)">库存变动记录</el-button>
              <el-button slot="reference" type="text" size="mini" @click="showSKUDetail(scope.row)">库存明细</el-button>
            </div>
            <el-button slot="reference" type="text" size="mini">{{ scope.row.SKUCode }}</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="图片" prop="ImagePath" align="center" width="120px">
        <template slot-scope="scope">
          <el-image class="sku-img" :preview-src-list="[scope.row.ImagePath]" :src="scope.row.ImagePath" v-if="scope.row.ImagePath"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="Name" align="center"></el-table-column>
<!--      <el-table-column label="颜色" prop="MainBillCode" align="center" width="180"></el-table-column>-->
      <el-table-column label="尺寸" prop="MainBillCode" align="center">
        <template slot-scope="scope">
          <span>{{ `${scope.row.Length}*${scope.row.Width}*${scope.row.Height}` }}</span>
        </template>
      </el-table-column>
      <el-table-column label="尺码" prop="Size" align="center"></el-table-column>
      <el-table-column label="规格" prop="ModelNumber" align="center"></el-table-column>
      <el-table-column label="库存" prop="Quantity" align="center"></el-table-column>
      <el-table-column label="重量" prop="Weight" align="center"></el-table-column>
      <el-table-column label="备注" prop="Rem" align="center"></el-table-column>
      <el-table-column
          label="操作"
          min-width="120px"
          align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="editRow(scope.row)">编辑</el-button>
          <el-button type="text" size="mini" @click="delRow(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="u-between-flex mt20" >
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleChange"
          :current-page="PageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="PageRows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
      <el-button @click="toWarehouseApplication" type="primary" size="medium">添加入仓单</el-button>
    </div>

    <el-dialog :close-on-click-modal="false" :title="textMap[dialogStatus]" width="90rem" :visible.sync="visible">
      <el-form size="small" :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="80px">
        <el-form-item label="商品品类" prop="ProductId">
          <el-select style="width: 30rem" v-model="ruleForm.ProductId" placeholder="请选择商品品类">
            <el-option
                v-for="item in options['product']"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="SKU编码" prop="SKUCode">
          <el-input v-model="ruleForm.SKUCode" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="Name">
          <el-input v-model="ruleForm.Name" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
<!--        <el-form-item label="颜色" prop="GoodsName">-->
<!--          <el-color-picker v-model="ruleForm.color"></el-color-picker>-->
<!--        </el-form-item>-->
        <el-form-item label="尺码" prop="Size">
          <el-input v-model="ruleForm.Size" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item label="重量" prop="Weight">
          <el-input v-model="ruleForm.Weight" type="text" size="small" autocomplete="off" style="width: 30rem">
            <template slot="append">KG</template>
          </el-input>
        </el-form-item>
        <el-form-item label="尺寸">
          <div class="u-between-flex">
            <el-input class="mr10" v-model="ruleForm.Length" placeholder="长" type="text" size="small" autocomplete="off" style="width: 20rem">
              <template slot="append">CM</template>
            </el-input>
            <el-input class="mr10" v-model="ruleForm.Width" placeholder="宽" type="text" size="small" autocomplete="off" style="width: 20rem">
              <template slot="append">CM</template>
            </el-input>
            <el-input v-model="ruleForm.Height" placeholder="高" type="text" size="small" autocomplete="off" style="width: 20rem">
              <template slot="append">CM</template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="规格" prop="ModelNumber">
          <el-input v-model="ruleForm.ModelNumber" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :http-request="progressAvatar"
              :before-upload="beforeAvatarUpload">
            <img v-if="skuGoodsUrl || ruleForm.ImagePath" :src="skuGoodsUrl || ruleForm.ImagePath" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注" prop="Rem">
          <el-input v-model="ruleForm.Rem" type="textarea" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: left">
        <el-button size="small" v-loading="loading" type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button size="small" @click="visible = false">取消</el-button>
      </div>
    </el-dialog>
    <div style="display: none; position: relative">
      <img id="barcode" style="width: 80%; position: absolute; top: 20%; left: 50%; transform: translateX(-50%)" src="" alt="">
    </div>
    <sku-record :show="showRecode" @close="handleRecordClose" :s-k-u-id="currentSKUId"></sku-record>
    <warehouse-application ref="warehouseApplication" @close="handleWareHouseApplicationClose" :show="showWarehouseApplication" :c-o-d-apply-infos="multipleSelection"></warehouse-application>
    <sku-detail :show="showSkuRecode" @close="handleSkuRecordClose" :s-k-u-id="currentSKUId"></sku-detail>
  </div>
</template>

<script>
import { addCodSKU, delCodSKU, getCODProduct, getCodSKU, updateCodSKU } from "@/api/cod";
import { mapGetters } from "vuex"
import { uploadImage } from "@/api/system";
import JsBarcode from "jsbarcode";
import printJS from "print-js";
import SkuRecord from "@/views/member/cod/SkuRecord.vue";
import WarehouseApplication from "@/views/member/cod/WarehouseApplication.vue";
import SkuDetail from "@/views/member/cod/SkuDetail.vue";
export default {
  data() {
    return {
      PageIndex: 1,
      showRecode: false,
      showSkuRecode: false,
      showWarehouseApplication: false,
      currentSKUId: 0,
      multipleSelection: [],
      visible: false,
      PageRows: 10,
      ruleForm: {},
      options: {},
      file: undefined,
      query: {
        SKU: "",
        InventoryTypeId: "",
        GoodsTypeId: ""
      },
      GoodsTypeOptions: [],
      skuGoodsUrl: "",
      rules: {
        ProductId: [
          { required: true, message: '请选择商品品类', trigger: 'change' }
        ],
        SKUCode: [
          { required: true, message: '请输入SKU编号', trigger: 'blur' },
        ],
        Name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
        ]
      },
      textMap: {
        create: "添加商品SKU",
        update: "编辑商品SKU"
      },
      dialogStatus: "",
      total: 0,
      listData: [],
      loading: false
    }
  },
  created() {
    this.getCodProduct()
    this.getData()
  },
  components: {
    SkuRecord,
    WarehouseApplication,
    SkuDetail
  },
  computed: {
    ...mapGetters(["userInfo"]),
    tableHeight() {
      return 'calc(100vh - 17.6rem - 147px)'
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showSKUDetail(row) {
      this.currentSKUId = row.Id
      this.showSkuRecode = true
    },
    handleRecordClose() {
      this.showRecode = false
    },
    handleSkuRecordClose() {
      this.showSkuRecode = false
    },
    handleWareHouseApplicationClose() {
      this.showWarehouseApplication = false
    },
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    handleChange(current) {
      this.PageIndex = current;
      this.getData()
    },
    editRow(row) {
      this.ruleForm = Object.assign({}, row)
      this.dialogStatus = "update"
      this.visible = true
    },
    delRow(row) {
      this.$confirm(`该操作会永久删除SKU编号为（${row.SKUCode}）的数据，是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      )
          .then(() => {
            const data = [row.Id]
            delCodSKU(data).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("删除成功！！")
                this.getData()
              } else {
                this.$message.warning(Msg)
              }
            })
          })
          .catch(() => {
            this.$message.info("已取消删除")
          });
    },
    addRow() {
      this.dialogStatus = "create"
      this.resetTemp()
      this.visible = true
    },
    uploadFile() {
      return new Promise((resolve, reject) => {
        if (!this.file) {
          resolve(undefined)
        } else {
          const extension =  this.file.name.slice(this.file.name.lastIndexOf('.') + 1);
          const formData = new FormData();
          formData.append('file', this.file);
          formData.append('FileName', `${this.userInfo.Id}${this.ruleForm.SKUCode}.${extension}`);
          formData.append('Path', 'SKUImg');
          uploadImage(formData).then(response => {
            const { Code, Msg, Data } = response
            if (Code === 200) {
              resolve(Data.ServerPath)
            } else {
              reject(Msg)
            }
          })
        }
      })
    },
    resetTemp() {
      this.ruleForm = {}
      this.file = ""
      this.skuGoodsUrl = ""
    },
    toWarehouseApplication() {
      if (!this.multipleSelection.length) {
        this.$message.warning("请选择SKU")
        return;
      }
      this.$refs.warehouseApplication.init()
      this.showWarehouseApplication = true
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const ServerPath = await this.uploadFile()
          if (this.dialogStatus === "create") {
            const data = {
              Name: this.ruleForm.Name,
              ProductId: this.ruleForm.ProductId,
              ClientId: this.userInfo.Id,
              SKUCode: this.ruleForm.SKUCode,
              ModelNumber: this.ruleForm.ModelNumber,
              Size: this.ruleForm.Size,
              Weight: this.ruleForm.Weight,
              Length: this.ruleForm.Length,
              Width: this.ruleForm.Width,
              Height: this.ruleForm.Height,
              Image: ServerPath,
              Rem: this.ruleForm.Rem
            }
            addCodSKU(data).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("添加成功！！！")
                this.visible = false
                this.getData()
              } else {
                this.$message.warning(Msg)
              }
            })
          } else {
            const data = {
              Name: this.ruleForm.Name,
              ProductId: this.ruleForm.ProductId,
              ClientId: this.userInfo.Id,
              SKUCode: this.ruleForm.SKUCode,
              ModelNumber: this.ruleForm.ModelNumber,
              Size: this.ruleForm.Size,
              Weight: this.ruleForm.Weight,
              Length: this.ruleForm.Length,
              Width: this.ruleForm.Width,
              Height: this.ruleForm.Height,
              Image: ServerPath || this.ruleForm.Image,
              Rem: this.ruleForm.Rem,
              Id: this.ruleForm.Id
            }
            updateCodSKU(data).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("修改成功！！！")
                this.visible = false
                this.getData()
              } else {
                this.$message.warning(Msg)
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getCodProduct() {
      const data = {
        PageIndex: 1,
        PageRows: 100,
      }
      getCODProduct(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.options['product'] = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    getData() {
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        QueryCodeType: 14,
        QueryCodes: this.query.SKU ? [this.query.SKU] : undefined,
        ProductId: this.query.GoodsTypeId || undefined
      }
      this.loading = true
      getCodSKU(data).then(response => {
        this.loading = false
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    printSkuCode(code) {
      JsBarcode("#barcode", code, {
        fontSize: 40,
        width: 4,
        fontOptions: "bold",
      })
      setTimeout(() => {
        printJS({
          type: 'html',
          printable: "barcode",
          scanStyles: false, //不适用默认样式
          style: '@page{size:auto;margin: 0cm 1cm 0cm 1cm;opacity: 0;}',
        })
      }, 500)
    },
    showInventory(row) {
      this.currentSKUId = row.Id
      this.showRecode = true
    },
    progressAvatar() {
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("图片超过大小！！");
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.skuGoodsUrl = e.target.result;
      };
      this.file = file
      return isLt2M;
    },
  }
}

</script>

<style scoped lang="scss">
.sku-img {
  width: 96px;
  height: 40px;
  border-radius: 8px;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader {
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
.sku-container {
  padding: 20px;
  .sku-title {
    padding: 1.5rem 2.5rem;
    color: #225493;
    background-color: #F0F4FB;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  .queryItems {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-items: center;
  }
}
</style>