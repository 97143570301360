import request from "@/utils/requests"

export function getCODProduct(data) {
    return request({
        url: '/COD_Product/GetDataPage',
        method: 'post',
        data
    })
}


export function delCODProduct(data) {
    return request({
        url: '/COD_Product/DeleteData',
        method: 'post',
        data
    })
}


export function addCODProduct(data) {
    return request({
        url: '/COD_Product/AddData',
        method: 'post',
        data
    })
}

export function updateCODProduct(data) {
    return request({
        url: '/COD_Product/UpdateData',
        method: 'put',
        data
    })
}


export function getCodSKU(data) {
    return request({
        url: '/COD_SKU/GetDataPage',
        method: 'post',
        data
    })
}

export function addCodSKU(data) {
    return request({
        url: '/COD_SKU/AddData',
        method: 'post',
        data
    })
}

export function updateCodSKU(data) {
    return request({
        url: '/COD_SKU/UpdateData',
        method: 'put',
        data
    })
}


export function delCodSKU(data) {
    return request({
        url: '/COD_SKU/DeleteData',
        method: 'post',
        data
    })
}

export function getCodSKUStockLog(data) {
    return request({
        url: '/COD_SKU/GetStockLogDataPage',
        method: 'post',
        data
    })
}


export function getCodSKUDetail(data) {
    return request({
        url: '/COD_SKU/GetStockDataPage',
        method: 'post',
        data
    })
}

export function addCodApply(data) {
    return request({
        url: '/COD_Apply/AddData',
        method: 'post',
        data
    })
}


export function getCodSKUApply(data) {
    return request({
        url: '/COD_Apply/GetDataPage',
        method: 'post',
        data
    })
}

export function getCodSKUApplyDetail(data) {
    return request({
        url: '/COD_Apply/GetDataDetailsPage',
        method: 'post',
        data
    })
}


export function delCodSKUApply(data) {
    return request({
        url: '/COD_Apply/DeleteData',
        method: 'post',
        data
    })
}


export function updateCodSKUApply(data) {
    return request({
        url: '/COD_Apply/UpData',
        method: 'put',
        data
    })
}