<template>
  <el-dialog top="5vh" :close-on-click-modal="false" :append-to-body="body" :before-close="close" title="库存变动记录" width="65%" :visible.sync="show">
    <div class="queryItems mb20">
      <div class="u-start-flex mr15">
        <div class="default-font">所属仓库：</div>
        <el-select size="small" v-model="query.WareHouseId" clearable style="width: 20rem">
          <el-option
              v-for="item in warehouseOptions"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15">
        <div class="default-font">下单时间：</div>
        <el-date-picker
            size="small"
            v-model="query.dateRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
        </el-date-picker>
      </div>
      <el-button type="primary" class="" size="small" icon="el-icon-search" @click="getData">查询</el-button>
    </div>
    <el-table
        :data="listData"
        border
        size="mini"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="获取数据中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column label="日期" prop="CreateTime" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.CreateTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="仓库" prop="WareHouseName" align="center"></el-table-column>
      <el-table-column label="商品品类" prop="ProductName" align="center"></el-table-column>
      <el-table-column label="SKU编码" prop="SKUCode" align="center"></el-table-column>
      <el-table-column label="品名" prop="SKUName" align="center"></el-table-column>
      <el-table-column label="变动前数量" prop="OldValue" align="center"></el-table-column>
      <el-table-column label="变动数量" prop="Quantity" align="center"></el-table-column>
      <el-table-column label="变动后数量" prop="NewValue" align="center"></el-table-column>
      <el-table-column label="备注" prop="Rem" align="center"></el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </el-dialog>
</template>

<script>
import { getCodSKUStockLog } from "@/api/cod";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    body: {
      type: Boolean,
      default: false
    },
    SKUId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      query: {
        dateRange: []
      },
      pageIndex: 1,
      pageRows: 10,
      total: 0,
      listData: [],
      warehouseOptions: [],
      loading: false
    }
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.init()
        }
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    handleChange(current) {
      this.PageIndex = current;
      this.getData()
    },
    init() {
      this.getWareHouse()
      this.getData()
    },
    getData() {
      const data = {
        PageIndex: this.pageIndex,
        PageRows: this.pageRows,
        StartTime: this.query.dateRange.length ? this.query.dateRange[0] : undefined,
        EndTime: this.query.dateRange.length ? this.query.dateRange[1] : undefined,
        SKUId: this.SKUId,
        WareHouseId: this.query.WareHouseId
      }
      this.loading = true
      getCodSKUStockLog(data).then(response => {
        this.loading = false
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Data.Total
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.warehouseOptions = data
      })
    }
  }
}

</script>

<style scoped lang="scss">
.queryItems {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  align-items: center;
}
</style>